import { LitElement, css, html } from 'lit';
import { property, customElement } from 'lit/decorators.js';

import '@shoelace-style/shoelace/dist/components/card/card.js';
import '@shoelace-style/shoelace/dist/components/button/button.js';

import { styles } from '../styles/shared-styles';

@customElement('app-home')
export class AppHome extends LitElement {
  // For more information on using properties and state in lit
  // check out this link https://lit.dev/docs/components/properties/
  @property() message = 'Hello!';

  static get styles() {
    return [
      styles,
      css`
        h1,
        h2,
        h3,
        h4,
        h5,
        h6 {
          font-family: 'Epilogue', serif;
        }

        p,
        a {
          font-family: 'Epilogue', sans-serif;
        }

        /* Generic styles */
        html {
          scroll-behavior: smooth;
        }

        a {
          background-color: goldenrod;
          text-decoration: none;
          color: white;
          border-radius: 0.25rem;
          text-align: center;
          display: inline-block;
          transition: all 0.3s;
        }

        a:hover {
          opacity: 0.6;
        }

        /* Styles for the hero image */
        .hero {
          /* Photo by mnm.all on Unsplash */
          /* background: url('https://images.unsplash.com/photo-1518176258769-f227c798150e') center; */
          background-size: cover;
          background-color: #db8d00;
          padding: 4rem 2rem;
          /* grid styles */
          display: grid;
          grid-template-columns: repeat(auto-fit, minmax(240px, 1fr));
          align-items: center;
        }

        .hero > * {
          color: white;
        }

        .hero > h1 {
          font-size: 4rem;
          padding-bottom: 1rem;
        }

        .hero > article > p {
          font-size: 1.5rem;
          font-weight: 200;
        }

        .hero > article > a {
          padding: 1rem;
          margin-top: 0.75rem;
        }

        /* events styles */
        .events {
          padding: 2rem;
        }

        .events > ul {
          display: grid;
          grid-template-columns: repeat(auto-fit, minmax(320px, 1fr));
          grid-gap: 1rem;
        }

        .events > ul > li {
          border: 1px solid #e2e2e2;
          border-radius: 0.5rem;
        }

        .events > ul > li > figure {
          max-height: 220px;
          overflow: hidden;
          border-top-left-radius: 0.5rem;
          border-top-right-radius: 0.5rem;
          position: relative;
        }

        .events > ul > li > figure > img {
          width: 100%;
        }

        .events > ul > li > figure > figcaption {
          position: absolute;
          bottom: 0;
          background-color: rgba(0, 0, 0, 0.7);
          width: 100%;
        }

        .events > ul > li > figure > figcaption > h3 {
          color: white;
          padding: 0.75rem;
          font-size: 1.25rem;
        }

        .events > ul > li > p {
          font-size: 1rem;
          line-height: 1.5;
          padding: 1rem 0.75rem;
          color: #666666;
        }

        .events > ul > li > a {
          padding: 0.5rem 1rem;
          margin: 0.5rem;
        }

        /* footer */
        footer {
          background-color: #333;
          padding: 0.75rem;
          color: white;
          text-align: center;
          font-size: 0.75rem;
        }

        #welcomeBar {
          display: flex;
          justify-content: center;
          align-items: center;
          flex-direction: column;
        }

        #welcomeCard,
        #infoCard {
          padding: 18px;
          padding-top: 0px;
        }

        sl-card::part(footer) {
          display: flex;
          justify-content: flex-end;
        }

        @media (min-width: 750px) {
          sl-card {
            width: 70vw;
          }
        }

        @media (horizontal-viewport-segments: 2) {
          #welcomeBar {
            flex-direction: row;
            align-items: flex-start;
            justify-content: space-between;
          }

          #welcomeCard {
            margin-right: 64px;
          }
        }
      `,
    ];
  }

  constructor() {
    super();
  }

  async firstUpdated() {
    // this method is a lifecycle even in lit
    // for more info check out the lit docs https://lit.dev/docs/components/lifecycle/
    console.log('This is your home page');
  }

  share() {
    if ((navigator as any).share) {
      (navigator as any).share({
        title: 'PWABuilder pwa-starter',
        text: 'Check out the PWABuilder pwa-starter!',
        url: 'https://github.com/pwa-builder/pwa-starter',
      });
    }
  }

  render() {
    return html`
      <app-header></app-header>
      <section class="hero">
        <h1>Catch Up With</h1>
        <article>
          <p>
            Empower your networking experience with our app, tailored for
            professionals seeking alternatives to costly events. Engage in
            social gatherings, swap business contacts, and explore peripheral
            activities to maximize your business potential
          </p>
          <a href="#events">Browse events</a>
        </article>
      </section>
      <main>
        <div id="welcomeBar">
          <sl-card id="welcomeCard">
            <div slot="header">
              <h2>${this.message}</h2>
            </div>

            <p>
              Are you missing out on valuable networking opportunities due to
              the high cost of attending large events? Struggling to find a
              convenient way to connect with like-minded professionals without
              breaking the bank? Our app provides the perfect solution to bridge
              this gap and help you expand your network.
            </p>
            <p>
              Discover a seamless platform designed to bring professionals
              together for social gatherings, contact exchanges, and accessible
              local activities that complement major events. Unlock your
              business potential and forge meaningful connections with our
              easy-to-use app, specifically tailored to meet your networking
              needs.
            </p>

            <p id="mainInfo">
              Welcome to the
              <a href="https://pwabuilder.com">PWABuilder</a>
              pwa-starter! Be sure to head back to
              <a href="https://pwabuilder.com">PWABuilder</a>
              when you are ready to ship this PWA to the Microsoft Store, Google
              Play and the Apple App Store!
            </p>

            ${'share' in navigator
              ? html`<sl-button
                  slot="footer"
                  variant="primary"
                  @click="${this.share}"
                  >Share this Starter!</sl-button
                >`
              : null}
          </sl-card>

          <sl-card id="infoCard">
            <h2>Technology Used</h2>

            <ul>
              <li>
                <a href="https://www.typescriptlang.org/">TypeScript</a>
              </li>

              <li>
                <a href="https://lit.dev">lit</a>
              </li>

              <li>
                <a href="https://shoelace.style/">Shoelace</a>
              </li>

              <li>
                <a
                  href="https://github.com/thepassle/app-tools/blob/master/router/README.md"
                  >App Tools Router</a
                >
              </li>
            </ul>
          </sl-card>

          <sl-button
            href="${(import.meta as any).env.BASE_URL}about"
            variant="primary"
            >Navigate to About</sl-button
          >
        </div>
      </main>
    `;
  }
}

